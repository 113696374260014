<template>
<div class="unauthorized-container">
    <h1 v-if="windowWidth > 599" class="header-text">{{ $t('UNAUTHORIZED.HEADER') }}</h1>
    <div class="gradient-bg">
        <NuxtImg
            src="/images/play/sad-quixort-avatar.png" :alt="$t('UNAUTHORIZED.IMG_ALT')" width="124"
            height="115" class="avatar-img"
        />
    </div>
    <h1 v-if="windowWidth <= 599" class="header-text" :class="{ padded: windowWidth <= 599 }">{{ $t('UNAUTHORIZED.HEADER') }}</h1>
    <p class="paragraph-text" :class="{ padded: windowWidth <= 599 }">
        {{ $t('UNAUTHORIZED.ERROR_TEXT') }}
    </p>
    <ButtonElement class="ok-btn" v-on:click="close">{{ $t('UNAUTHORIZED.BUTTON_TEXT') }}</ButtonElement>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ButtonElement from './ButtonElement.vue'

export default defineComponent({
    components: { ButtonElement },

    data() {
        return {
            windowWidth: window.innerWidth
        }
    },

    mounted() {
        window.addEventListener('resize', this.onResize)
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize)
    },

    methods: {
        close() {
            const redirect = sessionStorage.getItem('redirect')
            if (redirect !== null && !redirect.includes('token')) {
                window.location.href = redirect
            } else {
                void this.$router.push({
                    path: '/user/login'
                })
            }
        },

        onResize() {
            this.windowWidth = window.innerWidth
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.unauthorized-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 62px;
    max-width: 480px;

    @include mq-xsmall {
        margin-top: -30px;
        width: 100%;
    }
}

.header-text {
    color: var(--neutral-50);
    font-size: 32px;
    font-weight: 800;
    line-height: 32px;

    @include mq-xsmall {
        align-self: flex-start;
    }
}

.gradient-bg {
    position: relative;
    width: 480px;
    height: 231.86px;

    background: radial-gradient(rgba(38, 129, 214, 0.2), transparent 75%);

    margin-top: 30px;
    margin-bottom: 40px;

    @include mq-xsmall {
        width: 100vw;
        height: 286px;
        margin-bottom: 6px;
    }
}

.avatar-img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: block;
    margin: auto;

    @include mq-xsmall {
        width: 134px;
        height: 123.5px;
    }
}

.paragraph-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
}

.ok-btn {
    margin-top: 16px;
    width: 100%;

    @include mq-xsmall {
        width: 95%;
        height: 48px;
    }
}
</style>
