<template>
<TextInput
    v-model="fieldValue"
    v-model:invalid="invalid"
    v-bind="$attrs"
    :class="{ invalid }"
    v-on:blur="validateValue"
    v-on:keydown="clearTypingTimer(typingTimer)"
    v-on:keyup="startTypingTimer()"
>
    <template v-if="invalid" v-slot:flag>
        <FieldErrorFlag error-text="Please enter a valid username." />
    </template>
    <template v-slot:hints>
        <Hint :status="isSomeOrMoreCharacters" :label="minLength + '+ characters'" />
        <Hint :status="uniqueUsername" label="Unique" />
        <Hint :status="safeForWork" label="Safe for work" />
    </template>
</TextInput>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { JBGApi } from '$services/api'
import TextInput from '$components/input/fields/TextInput.vue'
import FieldErrorFlag from '$components/input/FieldErrorFlag.vue'
import Hint, { type HintStatus } from '$components/input/Hint.vue'

export default defineComponent({
    components: {
        TextInput,
        FieldErrorFlag,
        Hint
    },

    inheritAttrs: false,

    props: {
        value: String,
        modelValue: String,
        minLength: {
            type: Number,
            default: 4
        },
        maxLength: {
            type: Number
        }
    },

    emits: [
        'input',
        'update:modelValue',
        'update:invalid',
        'keyup',
        'keydown',
        'focus',
        'blur'
    ],

    data() {
        return {
            fieldDirty: false,
            invalid: false,
            invalidationReason: undefined,
            safeForWork: <HintStatus>'pass',
            uniqueUsername: <HintStatus>'pass',
            typingTimer: <ReturnType<typeof setTimeout> | null> null
        }
    },

    computed: {
        fieldValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.fieldDirty = true
                this.$emit('update:modelValue', value)
            }
        },
        isSomeOrMoreCharacters(): HintStatus {
            if (this.fieldValue.length === 0) return undefined
            return this.fieldValue.length >= this.$props.minLength ? 'pass' : 'fail'
        }
    },

    watch: {
        invalid(newValue: boolean) {
            this.$emit('update:invalid', newValue)
        }
    },

    methods: {
        validateValue() {
            if (!this.fieldDirty) return
            this.invalid = false
            this.invalidationReason = undefined

            if (this.fieldValue.length === 0) {
                this.invalid = true
                this.invalidationReason = 'usernameBlank'
                return
            }
            if (this.isSomeOrMoreCharacters === 'fail') {
                this.invalid = true
                this.invalidationReason = 'tooShort'
                return
            }

            this.$user.checkUserName(this.fieldValue)
                .then(() => {
                    this.uniqueUsername = 'pass'
                    this.safeForWork = 'pass'
                })
                .catch((err) => {
                    this.invalid = true
                    if (err && err instanceof JBGApi.Error) {
                        if (err.code === JBGApi.UserJBGExists) {
                            this.invalidationReason = 'usernameTaken'
                            this.uniqueUsername = 'fail'
                        }
                        if (err.code === JBGApi.UserInappropriateUsername) {
                            this.invalidationReason = 'usernameNSFW'
                            this.safeForWork = 'fail'
                        }
                    } else {
                        this.$toast.add({
                            id: 'usernamecheck',
                            type: 'error',
                            text: this.$t('GENERAL.ERROR')
                        })
                    }
                })
        },

        clearTypingTimer(timer: ReturnType<typeof setTimeout> | null) {
            if (!timer) return
            clearTimeout(timer)
        },

        startTypingTimer() {
            this.clearTypingTimer(this.typingTimer)
            const DONE_TYPING_INTERVAL = 1500
            this.invalid = false
            this.typingTimer = setTimeout(this.validateValue, DONE_TYPING_INTERVAL)
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss";

</style>
