<template>
<TextInput
    v-model="fieldValue"
    v-model:invalid="invalid"
    v-bind="$attrs"
    type="email"
    :class="{ invalid }"
    v-on:blur="validateValue"
>
    <template v-if="invalid" v-slot:flag>
        <FieldErrorFlag :error-text="$t('FORM.ERRORS.EMAIL_INVALID')" />
    </template>
</TextInput>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TextInput from '$components/input/fields/TextInput.vue'
import FieldErrorFlag from '$components/input/FieldErrorFlag.vue'

// eslint-disable-next-line max-len
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default defineComponent({
    components: {
        TextInput,
        FieldErrorFlag
    },

    inheritAttrs: false,

    props: {
        value: String,
        modelValue: String
    },

    emits: [
        'input',
        'update:modelValue',
        'update:invalid',
        'keyup',
        'keydown',
        'focus',
        'blur'
    ],

    data() {
        return {
            fieldDirty: false,
            invalid: false,
            invalidationReason: undefined
        }
    },

    computed: {
        fieldValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.fieldDirty = true
                this.$emit('update:modelValue', value)
                this.$emit('update:invalid', this.invalid)
            }
        }
    },

    methods: {
        validateValue() {
            if (!this.fieldDirty) return

            this.invalid = false
            this.invalidationReason = undefined

            if (this.fieldValue.length === 0) {
                this.invalid = true
                this.invalidationReason = 'emailBlank'
            } else if (!emailRegex.test(this.fieldValue)) {
                this.invalid = true
                this.invalidationReason = 'emailPattern'
            }
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss";

</style>
